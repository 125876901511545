
.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    min-height: 90vh;
    max-width: 500px;
  }
  
  .header-login-page {
    padding: 2.4rem;
    display: flex;
    justify-content: center;
  }
  img.logo-login-cronos {
    width: 65%;
  }
  
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }
  @media (max-width: 768px) {
    .login-page {
      margin: 0 1rem;
    }
  }